import { Layout, Dropdown, Avatar, Button, notification } from "antd"; // Import notification
import { PiPassword } from "react-icons/pi";
import "./LayoutDefault.scss";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from "react-icons/md";
import { useState, useEffect } from "react";
import MenuSider from "../../components/MenuSider";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import { useAdmin } from "../../contexts/getAdminInfo";
import Notify from "../../components/Notify";
import { useTranslation } from "react-i18next";
import ChangePasswordModal from "../../components/common/ChangePasswordModal";

const LayoutDefault = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { adminInfo, setAdminInfo, clearAdminInfo } = useAdmin();
  const [collapsed, setCollapsed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { Sider, Content, Footer } = Layout;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [api, contextHolder] = notification.useNotification(); 

  const handleOpenChangePasswordModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "vi";
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleLogout = () => {
    clearAdminInfo();
    setAdminInfo(null);
    navigate("/login");
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const adminMenuItems = [
    {
      key: "profile",
      label: (
        <>
          <UserOutlined />
          <span style={{ paddingLeft: "10px" }}>{adminInfo?.full_name}</span>
        </>
      ),
      disabled: true,
    },
    {
      key: "change-password",
      label: (
        <>
          <PiPassword />
          <span style={{ paddingLeft: "10px" }}>{t("change_password")}</span>
        </>
      ),
      onClick: handleOpenChangePasswordModal,
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: (
        <>
          <LogoutOutlined />
          <span style={{ paddingLeft: "10px" }}>{t("logout")}</span>
        </>
      ),
      onClick: handleLogout,
    },
  ];

  const adminMenu = {
    items: adminMenuItems,
  };

  const languageMenu = {
    items: [
      {
        key: "en",
        label: (
          <Button
            onClick={() => changeLanguage("en")}
            type="text"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
              alt="English"
              style={{ width: "20px", marginRight: "10px" }}
            />
            {t("English")}
          </Button>
        ),
      },
      {
        key: "vi",
        label: (
          <Button
            onClick={() => changeLanguage("vi")}
            type="text"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg"
              alt="Vietnamese"
              style={{ width: "20px", marginRight: "10px" }}
            />
            {t("Vietnamese")}
          </Button>
        ),
      },
    ],
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));  
      const expiryTime = decodedToken.exp * 1000; 
      const currentTime = new Date().getTime();
      
      if (currentTime > expiryTime) {
        api.error({
          message: t('login_session_expired'),
          description: t('login_session_expired_content'),
          duration: 3,  
          icon: <InfoCircleOutlined style={{color: '#1890ff'}}/>,

          onClose: () => {
            localStorage.removeItem("token");  
            navigate("/login"); 
          },
          showProgress: true,  
        });
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();  
    }, 10000);  

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout className="layout">
      {contextHolder}  
      <ErrorBoundary>
        <header className="header">
          <Link
            to="/"
            className={
              "header__logo " + (collapsed && "header__logo--collapsed")
            }
          >
            <img
              src={
                collapsed
                  ? "/assets/images/logo/logo-3.png"
                  : "/assets/images/logo/logo-1.png"
              }
              alt="Logo"
            />
          </Link>
          <div className="header__nav">
            <div className="header__nav-left">
              <div
                className="header__collapse"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
            </div>
            <div className="header__nav-right">
              <Notify />
              <Button
                type="text"
                icon={
                  isFullscreen ? (
                    <MdOutlineFullscreenExit style={{ fontSize: "26px" }} />
                  ) : (
                    <MdOutlineFullscreen style={{ fontSize: "26px" }} />
                  )
                }
                style={{ marginLeft: "5px" }}
                onClick={toggleFullscreen}
              />
              <Dropdown menu={languageMenu} trigger={["click"]}>
                <Button
                  type="text"
                  icon={<GlobalOutlined style={{ fontSize: "18px" }} />}
                  style={{ marginLeft: "5px" }}
                />
              </Dropdown>

              <Dropdown menu={adminMenu} trigger={["click"]}>
                <Avatar
                  size="large"
                  src={
                    adminInfo?.avatar ||
                    "https://res.cloudinary.com/drotlopmt/image/upload/v1730265533/3_zfces7.png"
                  }
                  style={{ cursor: "pointer", margin: "0px 15px 0px 10px" }}
                />
              </Dropdown>

              {isModalVisible && (
                <ChangePasswordModal onClose={handleCloseChangePasswordModal} />
              )}
            </div>
          </div>
        </header>
      </ErrorBoundary>

      <Layout>
        <Sider theme="light" collapsed={collapsed} width="220px">
          <MenuSider />
        </Sider>
        <Content className="content">
          <Outlet />
          <Footer style={{ textAlign: "center" }} className="footer">
            ©{new Date().getFullYear()} Created by BookVibe
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDefault;
