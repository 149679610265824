import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  getShippingFeesList,
  addShippingFee,
  updateShippingFee,
  deleteShippingFee,
} from "../../api/shippingFee.api";
import {
  Table,
  Input,
  InputNumber,
  Button,
  message,
  Row,
  Col,
  Modal,
  Spin,
  Space,
} from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../components/common/FormatPrice";

function ShippingFee() {
  const { t } = useTranslation("shippingFees");
  const [shippingFees, setShippingFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newShippingFee, setNewShippingFee] = useState({
    province: "",
    fee: 0,
  });
  const [editingShippingFeeId, setEditingShippingFeeId] = useState(null);
  const [editingShippingFee, setEditingShippingFee] = useState({
    province: "",
    fee: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchShippingFees = async () => {
      try {
        const result = await getShippingFeesList();
        setShippingFees(result.data);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách phí vận chuyển:", error);
        setError(t("errorLoadingShippingFees"));
      } finally {
        setLoading(false);
      }
    };

    fetchShippingFees();
  }, []);

  const handleAdd = async () => {
    if (!newShippingFee.province || newShippingFee.fee <= 0) {
      message.error(t("pleaseEnterShippingFeeInformation"));
      return;
    }
    try {
      const result = await addShippingFee(newShippingFee);
      setShippingFees([...shippingFees, result.data]);
      setNewShippingFee({ province: "", fee: 0 });
      message.success(t("successAdd"));
    } catch (error) {
      message.error(t("errorAddingShippingFee"));
    }
  };
  const handleDelete = (shippingFeeId) => {
    Modal.confirm({
      title: t("confirmDelete"),
      content: t("confirmDeleteMessage"),
      okText: t("confirm"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          await deleteShippingFee(shippingFeeId);
          message.success(t("successDelete"));
          setShippingFees(
            shippingFees.filter((fee) => fee._id !== shippingFeeId)
          );
        } catch (error) {
          const errorMessage = error.response?.data?.error;
          message.error(errorMessage);
        }
      },
    });
  };

  const handleEdit = async () => {
    if (!editingShippingFee.province || editingShippingFee.fee <= 0) {
      message.error(t("pleaseEnterNewShippingFee"));
      return;
    }
    try {
      const result = await updateShippingFee(
        editingShippingFeeId,
        editingShippingFee
      );
      setShippingFees(
        shippingFees.map((fee) =>
          fee._id === editingShippingFeeId ? result.data : fee
        )
      );
      message.success(t("successEdit"));
      setEditingShippingFeeId(null);
      setEditingShippingFee({ province: "", fee: 0 });
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const filteredShippingFees = shippingFees.filter((fee) =>
    fee.province.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: t("province"),
      dataIndex: "province",
      key: "province",
      align: "center",
    },
    {
      title: t("fee"),
      dataIndex: "fee",
      key: "fee",
      align: "center",
      render: (text) => formatCurrency(text),
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, shippingFee) => (
        <Space>
          <Button
            className="edit"
            onClick={() => {
              setEditingShippingFeeId(shippingFee._id);
              setEditingShippingFee({
                province: shippingFee.province,
                fee: shippingFee.fee,
              });
            }}
            style={{
              marginRight: "8px",
              borderColor: "#00a99d",
              color: "#00a99d",
            }}
          >
            <FaEdit /> {t("edit")}
          </Button>
          <Button
            className="delete"
            onClick={() => handleDelete(shippingFee._id)}
            danger
          >
            <FaTrash /> {t("delete")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <div style={{ marginBottom: "20px" }}>
        <h2>{t("title")}</h2>
        <p>{t("description")}</p>
      </div>

      <Input.Search
        placeholder={t("searchPlaceholder")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: "25%", marginBottom: "20px" }}
      />
      <Row gutter={16}>
        <Col span={8}>
          <div
            style={{
              background: "#f9f9f9",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px" }}>{t("addShippingFee")}</h4>
            <Input
              placeholder={t("provincePlaceholder")}
              value={newShippingFee.province}
              onChange={(e) =>
                setNewShippingFee({
                  ...newShippingFee,
                  province: e.target.value,
                })
              }
              style={{ marginBottom: "10px" }}
            />
            <InputNumber
              placeholder={t("fee")}
              onChange={(value) =>
                setNewShippingFee({
                  ...newShippingFee,
                  fee: value !== undefined ? Number(value) : 0,
                })
              }
              style={{ marginBottom: "10px", width: "100%" }}
              formatter={(value) =>
                value
                  ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ`
                  : ""
              }
              parser={(value) =>
                value ? value.replace(/\s?VNĐ/g, "").replace(/\./g, "") : "0"
              }
            />
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ width: "100%", backgroundColor: "#00bfa5" }}
            >
              {t("addShippingFee")}
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table
              dataSource={filteredShippingFees
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((fee) => ({ ...fee, key: fee._id }))}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: filteredShippingFees.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </Col>
      </Row>
      
      <Modal
        title={t("editShippingFee")}
        open={!!editingShippingFeeId}
        onCancel={() => {
          setEditingShippingFeeId(null);
          setEditingShippingFee({ province: "", fee: 0 });
        }}
        onOk={handleEdit}
        cancelText={t("cancel")} 
        okText={t("confirm")}  
      >
        <Input
          placeholder={t("province")}
          value={editingShippingFee.province}
          onChange={(e) =>
            setEditingShippingFee({
              ...editingShippingFee,
              province: e.target.value,
            })
          }
          style={{ marginBottom: "10px" }}
        />
        <InputNumber
          placeholder={t("fee")}
          value={editingShippingFee.fee}
          onChange={(value) =>
            setEditingShippingFee({
              ...editingShippingFee,
              fee: value !== undefined ? value : 0,
            })
          }
          formatter={(value) =>
            value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ` : ""
          }
          parser={(value) =>
            value ? Number(value.replace(/\s?VNĐ/g, "").replace(/\./g, "")) : 0
          }
          style={{ width: "100%" }}
        />
      </Modal>
    </>
  );
}

export default ShippingFee;
