import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Switch,
  DatePicker,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { InboxOutlined } from "@ant-design/icons";
import { createBook } from "../../../api/book.api";
import { getCategoriesList } from "../../../api/category.api";
import { getAuthorsList } from "../../../api/author.api";
import { getPublishersList } from "../../../api/publisher.api";
import { getAgeGroupsList } from "../../../api/ageGroup.api";
import { useNavigate } from "react-router-dom";
import "./AddBook.scss";
import { Helmet } from "react-helmet";
import { MyUploadAdapterPlugin } from "../../../utils/MyUploadAdapter";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { Dragger } = Upload;

const AddBook = () => {
  const { t } = useTranslation("addBook");
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    categories: false,
    authors: false,
    publishers: false,
    ageGroups: false,
  });
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [showChildSelect, setShowChildSelect] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          categoryResponse,
          authorResponse,
          publisherResponse,
          ageGroupResponse,
        ] = await Promise.all([
          getCategoriesList(),
          getAuthorsList(),
          getPublishersList(),
          getAgeGroupsList(),
        ]);

        if (categoryResponse.success) {
          const categoriesData = categoryResponse.data;
          setCategories(categoriesData);
          setParentCategories(categoriesData);
        }
        if (authorResponse.success) setAuthors(authorResponse.data);
        if (publisherResponse.success) setPublishers(publisherResponse.data);
        if (ageGroupResponse.success) setAgeGroups(ageGroupResponse.data);
      } catch (error) {
        message.error("Lỗi khi lấy dữ liệu");
      } finally {
        setLoadingStates({
          categories: false,
          authors: false,
          publishers: false,
          ageGroups: false,
        });
      }
    };
    fetchData();
  }, []);

  const handleParentChange = (parentId) => {
    const selectedParentCategory = categories.find(
      (cat) => cat._id === parentId
    );
    setChildCategories(selectedParentCategory?.children || []);
    setShowChildSelect(!!selectedParentCategory?.children?.length);
    form.setFieldsValue({ child_category: null });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("description", description);
    formData.append("price", values.price);
    formData.append("discount_price", values.discount_price);
    formData.append(
      "category",
      values.child_category || values.parent_category
    );
    formData.append("author", values.author);
    formData.append("age_group", values.age_group);
    formData.append("publisher", values.publisher);
    formData.append("is_hidden", values.is_hidden);
    formData.append("stock", values.stock);

    formData.append(
      "detail[published_at]",
      values.published_at.format("YYYY-MM-DD")
    );
    formData.append("detail[format]", values.format);
    formData.append("detail[pages]", values.pages);
    formData.append("detail[dimensions][length]", values.length);
    formData.append("detail[dimensions][width]", values.width);
    formData.append("detail[dimensions][height]", values.height);
    formData.append("detail[weight]", values.weight);

    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      await createBook(formData);
      message.success(t("success_message"));
      navigate("/book-list");
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleEditorChange = (event, editor) => {
    setDescription(editor.getData());
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file) => {
      const isValidType =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isValidType) {
        message.error(t("invalid_image_format"));
        return Upload.LIST_IGNORE;
      }
      setSelectedFiles((prevFiles) => [...prevFiles, file]);
      return false;
    },
    onRemove: (file) => {
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((item) => item.uid !== file.uid)
      );
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("add_new_book")}</title>
      </Helmet>

      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#334155" }}>
          {t("add_new_book")}
        </h2>

        <Button
          className="btn-add-book"
          onClick={() => form.submit()}
          loading={loading}
        >
          {t("create_new_book")}
        </Button>
      </Row>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={15}>
            <div className="add-book-section">
              <h3 className="add-book-title">{t("book_info")}</h3>
              <Form.Item
                name="title"
                label={t("book_title")}
                rules={[{ required: true, message: t("rules.required_title") }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>

              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="price"
                    label={t("book_price")}
                    rules={[
                      { required: true, message: t("rules.required_price") },
                    ]}
                  >
                    <InputNumber
                      placeholder="VNĐ"
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        value && `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ`
                      }
                      parser={(value) =>
                        value?.replace(/\s?VNĐ/g, "").replace(/\./g, "")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="discount_price"
                    label={t("discount_price")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_discount_price"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="VNĐ"
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        value && `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ`
                      }
                      parser={(value) =>
                        value?.replace(/\s?VNĐ/g, "").replace(/\./g, "")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="stock"
                    label={t("stock")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_stock"),
                      },
                    ]}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={t("full_description")}
                name="description"
                rules={[
                  { required: true, message: t("rules.required_description") },
                ]}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onChange={handleEditorChange}
                  config={{ extraPlugins: [MyUploadAdapterPlugin] }}
                />
              </Form.Item>
            </div>

            <div className="add-book-section">
              <h3 className="add-book-title">{t("detailed_info")}</h3>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="published_at"
                    label={t("published_date")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_published_date"),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder={t("published_date_placeholder")}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="format" label={t("format")}>
                    <Input
                      placeholder={t("format")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="pages"
                    label={t("pages")}
                    rules={[
                      { required: true, message: t("rules.required_pages") },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("pages")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="weight" label={t("weight")}>
                    <InputNumber
                      placeholder={t("weight")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="length"
                    label={t("dimensions_length")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_length"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_length")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="width"
                    label={t("dimensions_width")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_width"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_width")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="height"
                    label={t("dimensions_height")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_height"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_height")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="is_hidden"
                    label={t("is_hidden")}
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch
                      checkedChildren={t("hide")}
                      unCheckedChildren={t("show")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={9}>
            <div className="add-book-section">
              <h3 className="add-book-title">{t("upload_images")}</h3>
              <Form.Item
                name="images"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  return Array.isArray(e) ? e : e?.fileList || [];
                }}
                rules={[{ required: true, message: t("rules.required_image") }]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t("upload_text")}</p>
                </Dragger>
              </Form.Item>
            </div>

            <div className="add-book-section">
              <Form.Item
                name="author"
                label={t("author")}
                rules={[
                  { required: true, message: t("rules.required_author") },
                ]}
              >
                <Select
                  placeholder={t("select_author")}
                  loading={loadingStates.authors}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {authors.map((author) => (
                    <Option key={author._id} value={author._id}>
                      {author.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="publisher"
                label={t("publisher")}
                rules={[
                  { required: true, message: t("rules.required_publisher") },
                ]}
              >
                <Select
                  placeholder={t("select_publisher")}
                  loading={loadingStates.publishers}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {publishers.map((publisher) => (
                    <Option key={publisher._id} value={publisher._id}>
                      {publisher.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="age_group"
                label={t("age_group")}
                rules={[
                  { required: true, message: t("rules.required_age_group") },
                ]}
              >
                <Select
                  placeholder={t("select_age_group")}
                  loading={loadingStates.ageGroups}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {ageGroups.map((group) => (
                    <Option key={group._id} value={group._id}>
                      {group.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="add-book-section">
              <h3 className="add-book-title">{t("category")}</h3>
              <Form.Item
                name="parent_category"
                label={t("parent_category")}
                rules={[
                  {
                    required: true,
                    message: t("rules.required_parent_category"),
                  },
                ]}
              >
                <Select
                  placeholder={t("select_parent_category")}
                  loading={loadingStates.categories}
                  showSearch
                  onChange={handleParentChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {parentCategories.map((category) => (
                    <Option key={category._id} value={category._id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {showChildSelect && (
                <Form.Item name="child_category" label={t("child_category")}>
                  <Select
                    placeholder={t("select_child_category")}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {childCategories.map((category) => (
                      <Option key={category._id} value={category._id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddBook;
